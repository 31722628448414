import React from 'react'

import teacherIcon from '../images/teacher.png'


const TeacherCard = (props) => {

    const template = {

        name: 'Lauren',
        desc: 'I love introducing people of all ages to the joy of painting.',
        picture: teacherIcon

    }

    const { info = template } = props


    // const card_style = {

    //     width: '320px',
    //     height: '200px',
    //     background: 'white',
    //     boxShadow: '4px 8px 16px grey',
    //     margin: '20px',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     color: '#2d6120',

    // }

    const info_section = {

        display: 'flex',
        width: '160px',
        height: '200px',
        fontSize: '16px',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        alignItems: 'center',
        color: '#000000',
        // backgroundColor: '#f6f6f6',
        // backgroundColor: 'red',
        padding: '20px'

    }

    const avatar = {

        verticalAlign: 'middle',
        width: '60px',
        height: '70px',
        borderRadius: '50%'

    }

    return (

        // <div style={card_style}>

            

            <div style={info_section}>
            {/* <img style={avatar} src={info.dlLink} alt='teacher' /> */}
            <img style={avatar} src={info.picture} alt='teacher' />
                <h2 style={{fontWeight: 'bold', margin: '15px'}}>{info.name}</h2>
                <div style={{textAlign: 'center'}}>{info.desc}</div>
            </div>

        // </div>

    )

}

export { TeacherCard as default }

