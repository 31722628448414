import React, { forwardRef } from 'react'

// import TeacherCard from './TeacherCard.jsx'
import TeacherCard2 from './TeacherCard2.jsx'
// import teacherIcon from '../images/teacher.png'
import teacher2Icon from '../images/teacher2.jpg'
import teacher3Icon from '../images/lauren.jpg'


// const TeacherPage = (props) => {
const TeacherPage = forwardRef(function ArtPage(props, ref)  {

    const { title } = props

    const panel_style ={
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    }

    const card_style = {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        // backgroundColor: 'yellow'

    }

    const teacher2 = {

        name: 'Molly',
        desc: `I love art and painting, handcrafting and design. I began
        to learn painting when I was 15 years old. I studied at Minzu University of China
        (also known as Central University for Nationalities) when I was 18. I obtained
        a Bachelor of Fine Art after four years of professional studies. My university
        subjects included oil painting, Chinese painting, calligraphy, sketching and design. 
        Upon graduation I worked as an art editor. Meanwhile, I continued my
        professional development in order to further enhance my painting skills. In 2017,
        I was invited to participate in the 19th International Botany Congress Plant Art
        Exhibition where my artwork "Peony Flower" was shortlisted to enter the prizes'
        winning completion. I wish to continue my studies and achievements in the Fine Art profession.`,
        picture: teacher2Icon

    }

    const teacher3 = {

        name: 'Lauren',
        desc: `Perhaps like you, I knew nothing about painting a few years ago. I knew I loved colors and and thought I would enter the world of painting. 
        I fell in love with it immediately. The paintings you see are the results of years of trying and experimenting. Like me you can find joy in life, 
        through painting. Join us and discover your artistic side.`,
        picture: teacher3Icon

    }

    return (

        <div ref={ref} style={panel_style}>
            <h1>{title}</h1>

            <div style={card_style}>

                
               

            </div>
            {/* <TeacherCard /> */}
            {/* <TeacherCard2 info={teacher2} /> */}
            <TeacherCard2 info={teacher3} />
        </div>

    )

})

export { TeacherPage as default }