import React from 'react'
import phone from '../images/phone.png'
import email from '../images/email.png'

const ContactPage = () => {

    const page_style = {

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        flexDirection: 'column',
        paddingBottom: '18px',
        padding: '10px',

    }

    const img_style = {
        height: '30px', width: '30px'
    }

    const text_style = {
        margin: '5px'
    }

    const contact_info_style = {

        display: 'flex',
        // alignItems: 'center',
        textDecoration: 'none'

    }

    const map_outer = {

        // borderStyle: 'none',
        backgroundColor: 'white',
        // width: '512px',
        width: '100%',
        textAlign: 'center',
        height: '250px'
        // display: 'block',
        // margin: '0 auto'

    }

    return (

        <div style={page_style} >
            <h1>Contact us</h1>
            <p style={{textAlign: 'center'}}>Swing by for a cup of coffee and have a chat or contact us by phone or email.</p>
            <div style={{ display: 'flex', padding: '5px' }}>

                <a href="tel:+61411091661" style={contact_info_style}  >
                    <img style={img_style} src={phone} alt='phone' />
                    <p style={text_style}>+61 0411 091 661</p>
                </a>
                <a href="mailto:li_x_wu@icloud.com" style={contact_info_style} >
                    <img style={img_style} src={email} alt='email' />
                    <p style={text_style}>li_x_wu@icloud.com</p>
                </a>
            </div>
            <div style={map_outer}>
                {/* <div className="gmap_canvas"> */}

                {/* <iframe title='location' style={{padding: '15px', width:"338", height:"321", border:"0"}} src="https://maps.google.com/maps?q=Good%20Vibes%20cafe&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe> */}
                {/* <iframe title='location' style={{ overflow: 'hidden', background: 'none!important', padding: '15px', width: "338", height: "321", border: "0", scrolling: 'no' }} src="https://maps.google.com/maps?q=Good%20Vibes%20cafe&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe> */}
                {/* <iframe title='location' style={{ background: 'none!important', padding: '15px', width: "338", height: "321", border: "0", scrolling: 'no' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.216684723615!2d145.09345771579973!3d-37.85014911197332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6404d278c989d%3A0x2eb8c5f6bef7759c!2sProhibition%20Food%20and%20Wine!5e0!3m2!1sen!2sau!4v1623717646873!5m2!1sen!2sau"></iframe> */}
                {/* <iframe title='location' style={{width:"600", height:"450", border:"0", loading:"lazy"}} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJp6a-x_NA1moReX_P0BeMUgU&key=..."></iframe>  */}
                <iframe title='location' src="https://storage.googleapis.com/maps-solutions-4ibkemka16/locator-plus/serl/locator-plus.html"
                style={{/* width:"350", height:"350",  */border:"0", loading:"lazy"}}> </iframe>

                {/* </div> */}
            </div>

        </div>

    )

}

export { ContactPage as default }

{/* <iframe src="https://storage.googleapis.com/maps-solutions-4ibkemka16/locator-plus/serl/locator-plus.html"
  width="100%" height="100%"
  style="border:0;"
  loading="lazy">
</iframe>

AIzaSyAZMpuGgzozibznsMp_jM3ZNRlgLBvnDj8 */}

//  https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1575.216684723615!2d145.09345771579973!3d-37.85014911197332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6404d278c989d%3A0x2eb8c5f6bef7759c!2sProhibition%20Food%20and%20Wine!5e0!3m2!1sen!2sau!4v1623717646873!5m2!1sen!2sau