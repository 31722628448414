import React, { forwardRef } from 'react'

import CourseCard from './CourseCard.jsx'
// import flowersImg from '../images/studio2.jpg'
// import IMG_1662 from '../images/IMG_1662.jpg'
// import IMG_5680 from '../images/images2024/IMG_5680.JPG'
// import img1693 from '../images/IMG_1693.jpg'
import img4797 from '../images/images2024/IMG_4797.jpeg'
// import studio from '../images/studio.jpg'
// import studio2 from '../images/studio2.jpg'
// 
import IMG_6407 from '../images/IMG_6407.jpeg'
import IMG_5096 from '../images/IMG_5096.jpeg'


// const CoursePage = (props) => {
const CoursePage = forwardRef(function ArtPage(props, ref)  {


    const coursesInfo = [

        // {
        //     name: 'Beginner Acrylic',
        //     desc: 'Simple color exercises in acrylic painting, this is an experience to get you started.',
        //     duration: '2 Hours',
        //     day: 'Fridays',
        //     img: flowersImg
    
        // },
        {
            name: 'Water Colour Explorer',
            desc: `You've had a go, but where to next? Add the next step in your painting skills and journey.`,
            duration: '4-5:30pm',
            day: 'Fridays',
            cost: '$60',
            img: IMG_6407
    
        },
        {
            name: 'Junior Water Color',
            desc: 'For children (10-15 years), use ours or bring your own paintbook, brushes and colors.',
            duration: '10-12pm',
            day: 'Sundays',
            cost: '$45',
            img: img4797
    
        },
        {
            name: 'Advanced Acrylic',
            desc: 'Advanced acrylic techniques and tips for the more experienced painter. Max 3 per class',
            duration: '1-3pm',
            day: 'Sundays',
            cost: '$65',
            img: IMG_5096
    
        },
    ]


    const { title } = props

    // const info = {

    //     name: 'Scenery',
    //     desc: 'A painting scenery course',
    //     img: flowersImg

    // }

    const panel_style = {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    }

    const card_style = {

        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        // backgroundColor: 'yellow'

    }

    return (

        <div ref={ref} style={panel_style}>
            <h1>{title}</h1>

            <div style={card_style}>

                <CourseCard info={coursesInfo[0]}/>
                <CourseCard info={coursesInfo[1]} />
                <CourseCard info={coursesInfo[2]}/>
                

            </div>
        </div>

    )

})

export { CoursePage as default }