import React, { forwardRef} from 'react'

import downarrow from '../images/downarrow.png'

// const Nav = ({refs, ref, action}) => {

const Nav = forwardRef(function Nav(props, ref)  {

    const { action } = props
    // console.log("ref:", ref)

    // const art = useRef(null)

    const nav_style = {

        // background: 'rgba(120, 0, 0, 0.5)',
        padding: '5px',
        width: '40px',
        height: '100px',
        zIndex: '100',
        position: 'fixed',
        bottom: '10px',
        right: '20px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: '15px',
        marginBottom: '15px'

    }

    const overlay_style = {


        // position: 'absolute',
        // bottom: '0',
        margin: '10px',
        // background: 'rgb(0, 0, 0)',
        background: 'rgba(125, 0, 0, 0.7)',
        // color: '#f1f1f1',
        // width: '70%'
        // transition: '.7s ease',
        // opacity: '0',
        color: 'white',
        fontSize: '8px',
        padding: '6px',
        borderRadius: '5px'
        // textAlign: 'center'

    }

    return (

        <div style={nav_style}>
            <div style={overlay_style}>
                <p>Scroll down for more</p>
                <img className='imgStyle' height='25' width='25' /*style={img_style} */ src={downarrow} alt='art' />
                <p onClick={ (e) => action(ref[0])}>Courses</p>
                <p onClick={ (e) => action(ref[1])}>Teachers</p>
                <p onClick={ (e) => action(ref[2])}>Art</p>
            </div>
            

        </div>

    )

})
    // }
export { Nav as default }