import React from 'react'

import '../css/Landing.css'
import '../css/Poppins.css'

import LandingImg from '../images/studio.jpg'

// <!-- Image Header -->
// <div class="w3-display-container w3-animate-opacity">
//   <img src="/w3images/sailboat.jpg" alt="boat" style="width:100%;min-height:350px;max-height:600px;">
//   <div class="w3-container w3-display-bottomleft w3-margin-bottom">  
//     <button onclick="document.getElementById('id01').style.display='block'" class="w3-button w3-xlarge w3-theme w3-hover-teal" title="Go To W3.CSS">LEARN W3.CSS</button>
//   </div>
// </div>

const Landing = () => {

    const LandingText = `At the little cafe at 164 Elgar Road, Box Hill South, the locals know there are many paintings hanging on the wall. Now, interested friends can finally participate in our 'sip and paint' activities. Our workshop is ready to go. As long as you are interested, come along and spend some time. Over two hours of relaxation, enjoy some coffee, tea or whatever relaxes you or puts you in the right frame of mind. There are healthy, tasty snacks to enjoy. Experience peace and joy as you follow our teachers learning and exploring your creativity. Take your work home and display it for your family and friends and for your own satisfaction.`

    const LandingText2 = `The social studio is on the rise. Experience the enjoyment that colour brings. Meet our community, your like minded friends and enjoy the coffee as you forget about your worries, forget time. Come back each time and continue your work. We have many experiences in art to suit all skill levels.`

    // const imgStyle = {

    //     width: '100%',
    //     maxHeight: '800px',
    //     minHeight: '350px'

    // }

    const landing_style = {

        backgroundImage: `url(${LandingImg})`,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'white',
        // fontSize: '0em'

    }

    // const info = {
    //     // display: 'flex',
    //     // justifyContent: 'center',
    //     // alignItems: 'center',
    //     // width: '100px',
    //     // height: '100px'
    //     fontSize: '45px',
    //     color: 'white'

    // }

    const glass = {

        // background: 'rgba(58, 187, 198, 0.43)',
        // background: 'rgb(43, 75, 107, 0.43)',
        background: 'rgb(26, 41, 56, 0.43)',
        // backgroundImage: `url(${LandingImg})`,
        borderRadius: '16px',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(5px)',
        border: '1px solid rgba(58, 187, 198, 0.23)',
        width: '70vw',
        height: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        color: 'white',
        // fontSize: '2vw'
        // fontSize: 'calc( 8px + (24 – 12) * ( (100vw – 400px) / ( 800 – 400) ))'
        // fontSize: '2.1vw'
        // backgroundSize: '50vw 50vh'
    }



    return (

        <div style={landing_style}>
            <h1>Our Story</h1>
            <div className='glass' style={glass}>
                <div style={{margin: '5px'}}>
                    {LandingText}
                    <br style={{ margin: '5px' }} />
                    {LandingText2}
                </div>
            </div>

            {/* <img src={LandingImg} alt='studio' style={imgStyle} /> */}

        </div>
    )

}

export { Landing as default }
