import React from 'react'

import '../css/Landing.css'

import LandingImg from '../images/brushes.webp'
// import Nav from './Nav.jsx'
import cambart from '../images/cambart.jpeg'


// <!-- Image Header -->
// <div class="w3-display-container w3-animate-opacity">
//   <img src="/w3images/sailboat.jpg" alt="boat" style="width:100%;min-height:350px;max-height:600px;">
//   <div class="w3-container w3-display-bottomleft w3-margin-bottom">  
//     <button onclick="document.getElementById('id01').style.display='block'" class="w3-button w3-xlarge w3-theme w3-hover-teal" title="Go To W3.CSS">LEARN W3.CSS</button>
//   </div>
// </div>

const Landing2 = () => {

    // const LandingText = `Learn Art With Us`

    // const imgStyle = {

    //     width: '100%',
    //     maxHeight: '800px',
    //     minHeight: '350px'

    // }

    const landing_style = {

        backgroundImage: `url(${LandingImg})`,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'white',
        fontFamily: 'Poppins'

    }

    // const info = {
    //     // display: 'flex',
    //     // justifyContent: 'center',
    //     // alignItems: 'center',
    //     // width: '100px',
    //     // height: '100px'
    //     fontSize: '45px',
    //     color: 'white'

    // }

    // const glass = {

    //     // background: 'rgba(58, 187, 198, 0.43)',
    //     // background: 'rgb(43, 75, 107, 0.43)',
    //     background: 'rgb(26, 41, 56, 0.43)',
    //     // backgroundImage: `url(${LandingImg})`,
    //     borderRadius: '16px',
    //     boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    //     backdropFilter: 'blur(5px)',
    //     border: '1px solid rgba(58, 187, 198, 0.23)',
    //     width: '70vw',
    //     height: '70vh',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     padding: '20px',
    //     color: 'white'
    //     // backgroundSize: '50vw 50vh'
    // }

    const overlay_style = {


        // position: 'absolute',
        // bottom: '0',
        margin: '10px',
        // background: 'rgb(0, 0, 0)',
        background: 'rgba(0, 0, 0, 0.5)',
        // color: '#f1f1f1',
        // width: '70%',
        // transition: '.7s ease',
        // opacity: '0',
        color: 'white',
        fontSize: '10px',
        padding: '10px',
        textAlign: 'center'

    }



    return (

        <div style={landing_style}>

            <h1 style={{ padding: '5vh', textAlign: 'center' }}>Good Vibes Art Studio</h1>
            <div style={{ margin: '10vw' }}>
                <h1 style={{ margin: '0', textAlign: 'center' }}>PAINT WITH US</h1>
                <hr style={{ color: 'white', margin: '0' }} />


                <div style={overlay_style}>
                    <p>We are proud to announce our works have been selected for display at the Camberwell Art Show</p>
                    <img height='50px' width='140px' src={cambart} alt='art' />


                </div>
            </div>

            {/* <img src={LandingImg} alt='studio' style={imgStyle} /> */}
            {/* <Nav /> */}


        </div>
    )

}

export { Landing2 as default }
