import React, { useRef } from 'react'

import Landing from './Landing.jsx'
import Landing2 from './Landing2.jsx'
// import Section from './Section.jsx'
import CoursePage from './CoursePage.jsx'
import TeacherPage from './TeacherPage.jsx'
import TeacherCard from './TeacherCard.jsx'
import CourseCard from './CourseCard.jsx'
import ContactPage from './ContactPage.jsx'
// import ClassesPage from './ClassesPage.jsx'
import ArtPage from './ArtPage.jsx'
import Nav from './Nav.jsx'

const AppRouter = () => {

    const main_style = {

        diaplay: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%'

    }

    const artRef = useRef(null)
    const coursesRef = useRef(null)
    const teachersRef = useRef(null)
    // const classesRef = useRef(null)

    const sections = [coursesRef, teachersRef, artRef]

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth'
        })
    }

    return (

        <div style={main_style}>
            <Nav refs={sections} ref={sections} action={scrollToSection} />
            <Landing2 />
            <Landing /> 
            <CoursePage ref={coursesRef} title='Experiences in Art' card={<CourseCard />} />
            <TeacherPage ref={teachersRef} title='Our Teachers' card={<TeacherCard />} />
            <ContactPage />
            <ArtPage ref={artRef} />
            
        </div>

    )

}

export { AppRouter as default }