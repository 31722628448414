import React from 'react'

import '../css/BookBtn.css'

const BookBtn = () => {


    const text_style = {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    }

    return (

        <div>
            <a href="tel:+61411091661" style={{ textDecoration: 'none' }}>
                <div className='bookbtn' style={text_style}>
                    Book Session
                </div>
            </a>
        </div>

    )

}

export { BookBtn as default }

// <div>
//             <a href="tel:+61411091661" >
//                 <img height='30px' width='40px' src={book} alt='book' />
//             </a>
//         </div>