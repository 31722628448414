import React from 'react'

import BookBtn from './BookBtn.jsx'

import LandingImg from '../images/studio.jpg'
// import book from '../images/book2.png'
// import flowersImg from '../images/studio2.jpg'

const CourseCard = (props) => {

    const template = {

        name: 'Painting',
        desc: 'A painting course and a short description of the course.',
        img: LandingImg

    }

    const { info = template } = props


    const card_style = {

        width: '320px',
        height: '200px',
        background: 'white',
        boxShadow: '4px 8px 16px grey',
        margin: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#2d6120'
        // backgroundColor: 'blue'

    }

    const info_section = {

        display: 'flex',
        width: '160px',
        height: '120px',
        fontSize: '14px',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        alignItems: 'center',
        color: '#000000',
        // backgroundColor: '#f6f6f6',
        // backgroundColor: 'green',
        // padding: '20px'

    }

    return (

        <div style={card_style}>

            <img height='200px' width='160px' src={info.img} alt='menu_pic' />

            <div>
                <div style={info_section}>

                    <div style={{ fontWeight: 'bold', paddingTop: '10px', textAlign: 'center' }}>{info.name}</div>
                    <div style={{ textAlign: 'center', padding: '8px', fontSize: '12px' }}>{info.desc}</div>

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '10px', paddingRight: '5px', fontWeight: 'bold', fontSize: '11px', color: 'blue'  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', fontWeight: 'bold', fontSize: '11px', color: 'blue' }}>

                        <div>{info.duration}</div>
                        <div>{info.day}</div>

                    </div>
                    <div>{info.cost}</div>
                    
                </div>
                <div style={{margin: '5px'}}>
                <BookBtn />
                </div>
            </div>

        </div>

    )

}

export { CourseCard as default }

