import React, { forwardRef } from 'react'

// import LandingImg from '../images/studio.jpg'
// import flowersImg from '../images/studio2.jpg'
// import img1662 from '../images/IMG_1662.jpg'
// import img1691 from '../images/IMG_1691.jpg'
// import img1692 from '../images/IMG_1692.jpg'
// import img1693 from '../images/IMG_1693.jpg'
// import img1695 from '../images/IMG_1695-2.jpg'
// import img1751 from '../images/IMG_1751.jpg'
// import img1752 from '../images/IMG_1752.jpg'
// import img4732 from '../images/IMG_4732.jpeg'
// import img4729 from '../images/IMG_4729.jpeg'
// import img5051 from '../images/IMG_5051.jpeg'
// import img5105 from '../images/IMG_5105.jpeg'
// import img5352 from '../images/IMG_5352.jpeg'

// Images 2024
import img4729 from '../images/images2024/IMG_4729.jpeg'
import img4732 from '../images/images2024/IMG_4732.jpeg'
import img4797 from '../images/images2024/IMG_4797.jpeg'
import img4991 from '../images/images2024/IMG_4991.jpeg'
import img5051 from '../images/images2024/IMG_5051.jpeg'
import img5632 from '../images/images2024/IMG_5632.jpeg'
import img5680 from '../images/images2024/IMG_5680.JPG'
import img5910 from '../images/images2024/IMG_5910.jpeg'
import img6253 from '../images/images2024/IMG_6253.JPG'
import img6284 from '../images/images2024/IMG_6284.jpeg'
import rabbits from '../images/images2024/Rabbits.png'



import '../css/ArtPage.css'

const ArtPage = forwardRef(function ArtPage(props, ref)  {

// const ArtPage = ({ref}) => {


    const title = 'Our Art'

    const panel_style = {

        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: '10px'

    }

    const column_style = {

        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 4px',
        flexDirection: 'column',

    }

    // const img_style = {

    //     marginTop: '8px',
    //     verticalAlign: 'middle',
    //     maxWidth: '300px'

    // }

    return (

        <div ref={ref} className='artpage' style={panel_style}>
            <h1>{title}</h1>
            {/* <div style={side_by_side}> */}
            <div className='artLayout'>
                <div style={column_style}>

                    <img className='imgStyle' /*style={img_style} */ src={img4732} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img4797} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img5051} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img5680} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img6253} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={rabbits} alt='art' />
                    

                </div>
                <div style={column_style}>

                    <img className='imgStyle' /*style={img_style} */ src={img4729} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img4991} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img5632} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img5910} alt='art' />
                    <img className='imgStyle' /*style={img_style} */ src={img6284} alt='art' />
                    {/* <img className='imgStyle' src={img1693} alt='art' /> */}


                </div>
            </div>
            {/* </div> */}
        </div>


    )

})

export { ArtPage as default }
